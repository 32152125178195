import AccessibilityLarge from 'assets/icons/accessibility-large.svg';
import AccessibilitySmall from 'assets/icons/accessibility-small.svg';
import Agenda from 'assets/icons/agenda.svg';
import AirconLarge from 'assets/icons/aircon-large.svg';
import AirconSmall from 'assets/icons/aircon-small.svg';
import Ampersand from 'assets/icons/ampersand.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';
import Attention from 'assets/icons/attention.svg';
import CarParking from 'assets/icons/car-parking.svg';
import CardLarge from 'assets/icons/card-large.svg';
import CardioLarge from 'assets/icons/cardio-large.svg';
import CardioSmall from 'assets/icons/cardio-small.svg';
import CaretDown from 'assets/icons/caret-down.svg';
import Check from 'assets/icons/check.svg';
import CheckmarkBold from 'assets/icons/checkmark-bold.svg';
import CheckmarkCircleFilled from 'assets/icons/checkmark-circle-filled.svg';
import CheckmarkCircle from 'assets/icons/checkmark-circle.svg';
import Checkmark from 'assets/icons/checkmark.svg';
import ChevronDown from 'assets/icons/chevron-down.svg';
import ChevronUp from 'assets/icons/chevron-up.svg';
import CircledCloseIcon from 'assets/icons/circled-close-icon.svg';
import CircuitLarge from 'assets/icons/circuit-large.svg';
import CircuitSmall from 'assets/icons/circuit-small.svg';
import CityCenterLarge from 'assets/icons/city-center-large.svg';
import CityCenterSmall from 'assets/icons/city-center-small.svg';
import CloseIcon from 'assets/icons/close-icon.svg';
import CoachingLarge from 'assets/icons/coaching-large.svg';
import CoachingSmall from 'assets/icons/coaching-small.svg';
import CoffeeLarge from 'assets/icons/coffee-large.svg';
import CoffeeSmall from 'assets/icons/coffee-small.svg';
import Cross from 'assets/icons/cross.svg';
import DaycareLarge from 'assets/icons/daycare-large.svg';
import DaycareSmall from 'assets/icons/daycare-small.svg';
import DietitianLarge from 'assets/icons/dietitian-large.svg';
import DietitianSmall from 'assets/icons/dietitian-small.svg';
import DumbbellLarge from 'assets/icons/dumbbell-large.svg';
import ErrorExclamation from 'assets/icons/error-exclamation.svg';
import Eye from 'assets/icons/eye.svg';
import Facebook from 'assets/icons/facebook.svg';
import FemaleLarge from 'assets/icons/female-large.svg';
import FemaleSmall from 'assets/icons/female-small.svg';
import Graph from 'assets/icons/graph.svg';
import CheckMark from 'assets/icons/green-check-mark.svg';
import GroupLarge from 'assets/icons/group-large.svg';
import GroupSmall from 'assets/icons/group-small.svg';
import Group from 'assets/icons/group.svg';
import InfoCircleFilled from 'assets/icons/info-circle-filled.svg';
import InfoCircle from 'assets/icons/info-circle.svg';
import InputCancel from 'assets/icons/input-cancel.svg';
import Instagram from 'assets/icons/instagram.svg';
import Line from 'assets/icons/line.svg';
import List from 'assets/icons/list.svg';
import LocationFilled from 'assets/icons/location-filled.svg';
import LocationLarge from 'assets/icons/location-large.svg';
import LocationOutline from 'assets/icons/location-outline.svg';
import LocationSmall from 'assets/icons/location-small.svg';
import Location from 'assets/icons/location.svg';
import MyLocation from 'assets/icons/my-location.svg';
import Mail from 'assets/icons/mail.svg';
import MaleLarge from 'assets/icons/male-large.svg';
import MaleSmall from 'assets/icons/male-small.svg';
import Map from 'assets/icons/map.svg';
import MarkerFilled from 'assets/icons/marker-filled.svg';
import Marker from 'assets/icons/marker.svg';
import Menu from 'assets/icons/menu.svg';
import Minus from 'assets/icons/minus.svg';
import NewEquipmentLarge from 'assets/icons/new-equipment-large.svg';
import NewEquipmentSmall from 'assets/icons/new-equipment-small.svg';
import OutdoorLarge from 'assets/icons/outdoor-large.svg';
import OutdoorSmall from 'assets/icons/outdoor-small.svg';
import ParkingLarge from 'assets/icons/parking-large.svg';
import ParkingSmall from 'assets/icons/parking-small.svg';
import Payment from 'assets/icons/payment.svg';
import Phone from 'assets/icons/phone.svg';
import PhysioLarge from 'assets/icons/physio-large.svg';
import PhysioSmall from 'assets/icons/physio-small.svg';
import Plus from 'assets/icons/plus.svg';
import MapsZoomIn from 'assets/icons/maps-zoomin.svg';
import MapsZoomOut from 'assets/icons/maps-zoomout.svg';
import PowerLarge from 'assets/icons/power-large.svg';
import PowerSmall from 'assets/icons/power-small.svg';
import Question from 'assets/icons/question.svg';
import SaunaLarge from 'assets/icons/sauna-large.svg';
import SaunaSmall from 'assets/icons/sauna-small.svg';
import ScaleLarge from 'assets/icons/scale-large.svg';
import ScaleSmall from 'assets/icons/scale-small.svg';
import Search from 'assets/icons/search.svg';
import ShowersLarge from 'assets/icons/showers-large.svg';
import ShowersSmall from 'assets/icons/showers-small.svg';
import SpaciousLarge from 'assets/icons/spacious-large.svg';
import SpaciousSmall from 'assets/icons/spacious-small.svg';
import SpinningLarge from 'assets/icons/spinning-large.svg';
import SpinningSmall from 'assets/icons/spinning-small.svg';
import SquashLarge from 'assets/icons/squash-large.svg';
import SquashSmall from 'assets/icons/squash-small.svg';
import StarFilled from 'assets/icons/star-filled.svg';
import StarHalf from 'assets/icons/star-half.svg';
import Star from 'assets/icons/star.svg';
import StopWatch from 'assets/icons/stop-watch.svg';
import Subscription from 'assets/icons/subscription.svg';
import Tiktok from 'assets/icons/tiktok.svg';
import TowelLarge from 'assets/icons/towel-large.svg';
import TowelSmall from 'assets/icons/towel-small.svg';
import TrainStationLarge from 'assets/icons/train-station-large.svg';
import TrainStationSmall from 'assets/icons/train-station-small.svg';
import UniqueBuildingLarge from 'assets/icons/unique-building-large.svg';
import UniqueBuildingSmall from 'assets/icons/unique-building-small.svg';
import User from 'assets/icons/user.svg';
import VirtualLarge from 'assets/icons/virtual-large.svg';
import VirtualSmall from 'assets/icons/virtual-small.svg';
import WeightsNew from 'assets/icons/weights-new.svg';
import Weights from 'assets/icons/weights.svg';
import Whistle from 'assets/icons/whistle.svg';
import World from 'assets/icons/world.svg';
import YogaLarge from 'assets/icons/yoga-large.svg';
import YogaSmall from 'assets/icons/yoga-small.svg';

const Icons: Record<string, SVGComponent> = {
  AccessibilityLarge,
  AccessibilitySmall,
  Agenda,
  AirconLarge,
  AirconSmall,
  Ampersand,
  ArrowLeft,
  ArrowRight,
  Attention,
  CarParking,
  CardioLarge,
  CardioSmall,
  CardLarge,
  CaretDown,
  Check,
  CheckmarkBold,
  CheckmarkCircleFilled,
  Checkmark,
  CheckmarkCircle,
  ChevronDown,
  ChevronUp,
  CityCenterLarge,
  CityCenterSmall,
  CircledCloseIcon,
  CircuitLarge,
  CircuitSmall,
  CloseIcon,
  CoachingLarge,
  CoachingSmall,
  CoffeeLarge,
  CoffeeSmall,
  Cross,
  DaycareLarge,
  DaycareSmall,
  DietitianLarge,
  DietitianSmall,
  DumbbellLarge,
  CheckMark,
  ErrorExclamation,
  Eye,
  Facebook,
  FemaleLarge,
  FemaleSmall,
  Graph,
  GroupLarge,
  GroupSmall,
  Group,
  InfoCircleFilled,
  InfoCircle,
  InputCancel,
  Instagram,
  Line,
  List,
  LocationFilled,
  LocationLarge,
  LocationOutline,
  LocationSmall,
  Location,
  MyLocation,
  Mail,
  MaleLarge,
  MaleSmall,
  Map,
  MapsZoomIn,
  MapsZoomOut,
  MarkerFilled,
  Marker,
  Menu,
  Minus,
  NewEquipmentLarge,
  NewEquipmentSmall,
  OutdoorLarge,
  OutdoorSmall,
  ParkingLarge,
  ParkingSmall,
  Payment,
  Phone,
  PhysioLarge,
  PhysioSmall,
  Plus,
  PowerLarge,
  PowerSmall,
  Question,
  SaunaLarge,
  SaunaSmall,
  ScaleLarge,
  ScaleSmall,
  Search,
  ShowersLarge,
  ShowersSmall,
  SpaciousLarge,
  SpaciousSmall,
  SpinningLarge,
  SpinningSmall,
  SquashLarge,
  SquashSmall,
  StarFilled,
  StarHalf,
  Star,
  Subscription,
  Tiktok,
  TowelLarge,
  TowelSmall,
  TrainStationLarge,
  TrainStationSmall,
  UniqueBuildingLarge,
  UniqueBuildingSmall,
  User,
  VirtualLarge,
  VirtualSmall,
  WeightsNew,
  Weights,
  Whistle,
  World,
  YogaLarge,
  YogaSmall,
  StopWatch,
};

export default Icons;
